/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Icon from '@ant-design/icons';

import BackupSvg from 'public/icons/backup.svg';
import BackgroundSvg from 'public/icons/bg.svg';
import BusinessCardSvg from 'public/icons/businessCard.svg';
import ElementsSvg from 'public/icons/elements.svg';
import ImageSvg from 'public/icons/image.svg';
import LogoutSvg from 'public/icons/logout.svg';
import PostcardSvg from 'public/icons/postcard.svg';
import ProfileSvg from 'public/icons/profile.svg';
import PromptTemplateSvg from 'public/icons/promptTemplate.svg';
import SceneSvg from 'public/icons/scene.svg';
import SettingsSvg from 'public/icons/settings.svg';
import StarPairSvg from 'public/icons/starPair.svg';
import TemplateSvg from 'public/icons/template.svg';
import TextSvg from 'public/icons/text.svg';
import TextureSvg from 'public/icons/texture.svg';
import TheSvg from 'public/icons/the.svg';
import TshirtSvg from 'public/icons/tshirt.svg';

export const Template = (props: any): any => (
  <Icon component={TemplateSvg} {...props} />
);
export const Element = (props: any): any => (
  <Icon component={ElementsSvg} {...props} />
);
export const Profile = (props: any): any => (
  <Icon component={ProfileSvg} {...props} />
);
export const Text = (props: any): any => (
  <Icon component={TextSvg} {...props} />
);
export const The = (props: any): any => <Icon component={TheSvg} {...props} />;
export const Texture = (props: any): any => (
  <Icon component={TextureSvg} {...props} />
);
export const Settings = (props: any): any => (
  <Icon component={SettingsSvg} {...props} />
);
export const BusinessCard = (props: any): any => (
  <Icon component={BusinessCardSvg} {...props} />
);
export const Postcard = (props: any): any => (
  <Icon component={PostcardSvg} {...props} />
);
export const Logout = (props: any): any => (
  <Icon component={LogoutSvg} {...props} />
);
export const Image = (props: any): any => (
  <Icon component={ImageSvg} {...props} />
);
export const Tshirt = (props: any): any => (
  <Icon component={TshirtSvg} {...props} />
);
export const Background = (props: any): any => (
  <Icon component={BackgroundSvg} {...props} />
);
export const Scene = (props: any): any => (
  <Icon component={SceneSvg} {...props} />
);
export const StarPair = (props: any): any => (
  <Icon component={StarPairSvg} {...props} />
);
export const PromptTemplate = (props: any): any => (
  <Icon component={PromptTemplateSvg} {...props} />
);
export const Backup = (props: any): any => (
  <Icon component={BackupSvg} {...props} />
);
