// URL of CDN that's in front of image proxy
export const CONTENT_URL = process.env.NEXT_PUBLIC_CONTENT_URL;

/**
 * Build a url for CMS image proxy
 * @param {String} url - this is generally an object name from s3 like storage
 * @param {Object} options
 * @param {Number} options.height desired height of the image
 * @param {Number} options.width desired width of the image
 * @param {Number} options.scale by what amount to multiply provided width and height
 * @returns
 */
export const buildImageProxyUrl = (
  url: string,
  {
    height,
    width,
    scale,
    outputFormat = 'webp',
  }: {
    height: number;
    width: number;
    scale: number;
    outputFormat?: string;
  }
): string => {
  return `${CONTENT_URL}/pr:sharp/rs:fit:${width * scale}:${
    height * scale
  }:0/plain/${url}@${outputFormat}`;
};
