type Environment = 'test' | 'development' | 'staging' | 'production';

const getEnvironment = (): Environment => {
  switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
    case 'test':
      return 'test';
    case 'development':
      return 'development';
    case 'staging':
      return 'staging';
    case 'production':
      return 'production';
    default:
      return (process.env.NODE_ENV as Environment | undefined) ?? 'staging';
  }
};

export const config = {
  ENVIRONMENT: getEnvironment(),
  BEDROCK_EMBEDDING_MODEL_ID: 'amazon.titan-embed-image-v1',
};
